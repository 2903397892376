import { render, staticRenderFns } from "./EmployeeReservation.vue?vue&type=template&id=b7b1989e&scoped=true&"
import script from "./EmployeeReservation.vue?vue&type=script&lang=js&"
export * from "./EmployeeReservation.vue?vue&type=script&lang=js&"
import style0 from "./EmployeeReservation.vue?vue&type=style&index=0&id=b7b1989e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7b1989e",
  null
  
)


    import installComponents from "!/Users/dhulsman/Projects/rentmagic.website.vue.hva/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BRow, BCard, BTable, BButton, BContainer} from 'bootstrap-vue'
    installComponents(component, {BCol, BRow, BCard, BTable, BButton, BContainer})
    

export default component.exports