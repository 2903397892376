<template>
  <b-container class="page-container">
    <bread-crumbs
      :bread-crumbs="breadCrumbs"
      :currentpage="`${this.$t('order')} ${reservationID}`"
    />
    <h1>
      {{
        $t('account-title-reservation', {
          reservationOrderID: reservationID
        })
      }}
    </h1>
    <b-card v-if="reservation.WarehouseID">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
          <p>
            <strong>{{ $t('status') }}</strong>
            <br />
            {{ reservationStatus }}
          </p>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
          <p>
            <strong>{{ $t('retrieval-location') }}</strong>
            <br />
            {{ reservation.WarehouseID }}
          </p>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
          <p>
            <strong>{{ $t('retrieval-date') }}</strong>
            <br />
            {{ format(reservation.DateTimeExpectedStart) }}
          </p>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
          <p>
            <strong>{{ $t('return-date') }}</strong>
            <br />
            {{ format(reservation.DateTimeExpectedEnd) }}
          </p>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col v-if="reservationItems.length > 0" sm="12">
        <b-table :fields="fields" :items="reservationItems" class="mt-4">
          <template v-slot:cell(ItemDescription)="data">
            <small
              v-if="data.item.ItemID && data.item.Composition"
              class="pl-3"
              >{{ data.item.ItemDescription }}</small
            >
            <span v-else>{{ data.item.ItemDescription }}</span>
          </template>
        </b-table>
      </b-col>
      <b-col
        v-if="reservation.Status != 'Canceled'"
        cols="12"
        class="text-right"
      >
        <hr />

        <b-button
          @click="$bvModal.show('prompt')"
          v-if="allowCancelOrder"
          variant="primary"
          class="float-right float-xs-center ml-3"
        >
          {{ $t('cancel-order') }}
          <icon-spinner v-if="addReservationToCartPending" />
        </b-button>
      </b-col>
    </b-row>
    <modal
      :message="promptMessage"
      @confirm="cancelReservation()"
      modalid="prompt"
    />
  </b-container>
</template>

<script>
import {
  getReservationReport,
  cancelReservation,
  cancelReservationItem
} from '@/services/ReservationService'
import GetVoucherItems from '@/services/voucher/GetVoucherItems'
import getVoucherReservation from '@/services/voucher/GetVoucherReservation'

import Modal from '@/components/global/modal-prompt.vue'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import IconSpinner from '@/components/icons/IconSpinner'
dayjs.extend(customParseFormat)

export default {
  components: {
    IconSpinner,

    modal: Modal
  },
  data() {
    return {
      reservationItems: {},
      reservation: {},
      addReservationToCartPending: false,
      sortBy: 'CompositionDescription',
      fields: [
        {
          key: 'CompositionDescription',
          class: 'd-none'
        },
        {
          key: 'ItemID',
          class: 'd-none'
        },
        {
          key: 'ItemDescription',
          label: this.$t('product')
        },

        {
          key: 'Amount',
          label: this.$t('amount'),
          class: 'text-center'
        },
        {
          key: 'Status',
          class: ''
        }
      ],
      breadCrumbs: [
        { page: 'index', title: 'Shop', path: '/' },
        { page: 'account', title: this.$t('myaccount'), path: '/account/user' }
      ]
    }
  },
  computed: {
    allowCancelOrder: function() {
      return dayjs(this.reservation.DateTimeExpectedStart).isAfter(
        dayjs().add(14, 'days')
      )
    },
    voucher: function() {
      return this.$route.params.voucher
    },
    reservationID() {
      return this.reservation.ReservationID ?? ''
    },
    paymentStatus: function() {
      if (!this.reservation.Balance) return this.$t('paid')
      return this.$t('not-payed')
    },
    promptMessage: function() {
      return this.$t('cancel-reservation-prompt', {
        reservationID: this.reservationID
      })
    },
    reservationStatus: function() {
      let status = this.reservation.ReservationStatus

      if (status === 'Active' || status === 'Open' || status === 'Actief')
        return this.$t('requested')

      if (status === 'Order') return this.$t('reservation-confirmed')

      if (status === 'Pick' || status === 'Picked')
        return this.$t('in-progress')

      if (status === 'Pending') return this.$t('rented')

      if (status === 'Closed') return this.$t('returned')

      if (status === 'Cancelled' || status === 'Expired')
        return this.$t('cancelled')

      if (status === 'Rejected') return this.$t('declined')

      return this.$t('requested')
    }
  },
  mounted: async function() {
    this.reservation = await getVoucherReservation({
      voucher: this.voucher
    })
    this.fetchReservationItems()
  },
  methods: {
    async fetchReservationItems() {
      const result = await GetVoucherItems({
        voucher: this.voucher
      })
      this.reservationItems = result.Collection
    },
    cancelReservationItemPrompt: async function({
      reservationOrderID,
      reservationItemID,
      adjustable,
      ItemDescription
    }) {
      if (adjustable === 1) {
        let cancel = confirm(
          this.$t('cancel-reservation-item-composition-prompt', {
            ItemDescription: ItemDescription
          })
        )
        if (cancel === false) return
      }

      if (!adjustable) {
        let cancel = confirm(
          this.$t('cancel-reservation-item-prompt', {
            ItemDescription: ItemDescription
          })
        )
        if (cancel === false) return
      }

      await cancelReservationItem({ reservationOrderID, reservationItemID })
      this.refreshReservationItems()
      this.reservation = await getVoucherReservation({
        voucher: this.voucher
      })
    },
    format(date) {
      return dayjs(date, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    getReport: async function() {
      await getReservationReport({
        reservationOrderID: this.reservation.ReservationOrderID,
        reportID: this.reservation.ReportID,
        attachmentToken: this.reservation.AttachmentToken,
        fileName: `${this.reservation.ReservationOrderID}-${this.reservation.ReportFileName}`
      })
    },

    refreshReservationItems: async function() {
      this.reservationItems = await GetVoucherItems({
        voucher: this.voucher
      })
    },
    cancelReservation: async function() {
      await cancelReservation({ reservationID: this.reservation.ReservationID })
      this.$router.push({
        name: 'account',
        query: {
          infoMessage: `${
            this.reservation.ReservationStatus === 'Active'
              ? this.$t('request')
              : this.$t('order')
          } ${this.$t('cancel-reservation-confirmation-notification', {
            reservationID: this.reservation.ReservationID
          })} `
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.card-h-100 .card {
  height: 100% !important;
  .card-body {
    padding-bottom: 100px;
  }
  .card-body-bottom {
    position: absolute;
    bottom: 20px;
  }
}
.prices {
  padding-right: 10px;
}
</style>
